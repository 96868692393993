import { Grid, Typography } from '@material-ui/core'
import React, {useEffect, useState} from 'react'

import useStyles from './pagesStyles'
import MobileMissionPage from '../Mobile/MobilePages/MobileMissionPage'
import SmallMissionPage from '../Pages/SmallWindowPages/SmallMissionPage'

import KelseyAndMacaylaPhoto from '../images/OfficialPhotos/OfficialPhoto3.png'
import KelseyPhoto from '../images/OfficialPhotos/OfficialPhoto6.png'
import MacaylaPhoto from '../images/OfficialPhotos/OfficialPhoto2.png'

import kAndMTogether from '../images/OfficialPhotos/OfficialPhoto13Cropped.jpg'
import kPhoto from '../images/OfficialPhotos/OfficialPhoto14.jpg'
import mPhoto from '../images/OfficialPhotos/OfficialPhoto17.jpg'
import MacaylaPanel from './AboutUsPage/MacaylaPanel'
import coffeeClink from '../images/OfficialPhotos/OfficialPhoto22.jpeg'


const MissionPage = () => {
  const classes = useStyles()

  const [isTooSmall, setIsTooSmall] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
        // setIsTooSmall(window.innerWidth < 1000);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);

  return (
    <>
    {isTooSmall ? (
      <SmallMissionPage KelseyAndMacaylaPhoto={coffeeClink} KelseyPhoto={KelseyPhoto} MacaylaPhoto={MacaylaPhoto}/>
    ) : (
      <div style={{justifyContent: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column'}} className={classes.LandingPage}>
        <Grid container spacing={4} style={{maxWidth: '100%'}}>
          <Grid item xs={12} >
            <Typography variant='h1' style={{fontFamily: 'inherit', textAlign: 'center', paddingTop: 40}}>
              About Us
            </Typography>
            <img src={coffeeClink} alt='coffeeClink' style={{ flex: 1, maxWidth: '40%'}}/>
            <div style={{width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: 60}}>
              <Typography variant='h4' style={{textAlign: 'center', width: '70%', fontFamily: 'Garbata'}}>
                Miele started as a long time dream of opening a coffee shop. Through wedding planning, 
                the owner Macayla, fell in love with the event industry and thought of Miele. Miele was born 
                with a passion for coffee and for lives special moments. Coffee brings people together, and Miele 
                hopes to be a part of the community around us. 
              </Typography>
            </div> 
          </Grid>
        </Grid>
        <MacaylaPanel/>
      </div>
    )}
    
    
    
    </>
  )
}

export default MissionPage