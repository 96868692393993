import { Grid, Typography } from '@mui/material'
import React from 'react'

import useStyles from './smallPagesStyles'
import LandingPageQuoteOnPhoto from '../../Components/LandingPageQuoteOnPhoto';
// import stock1 from '../../images/OfficialPhotos/OfficialPhoto4.png'
// import stock2 from '../../images/OfficialPhotos/OfficialPhoto8.png'


const SmallLandingPage = (props) => {
    const classes = useStyles();

    const stock1 = props.stock1
    const stock2 = props.stock2
    return (
        <div className={classes.LandingPage} style={{justifyContent: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column', paddingTop: 40}}>
            <Grid container spacing={4} style={{}}>
                <Grid item xs={12} className={classes.LandingHeaderText}>
                    <Typography variant='h2' style={{fontFamily: 'inherit', fontWeight: 500, paddingBottom: 40, textAlign: 'center', width: '100%', flex: 1}}>
                        Café De Miele
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{maxHeight: '50vh', display: 'flex'}}>
                    <img className={classes.logoImage} src={stock1} style={{objectFit: 'cover'}} alt="landingImage1"/>
                </Grid>
                <Grid item xs={12} style={{margin: '7vw'}}>
                    <Typography className={classes.LandingPageText} style={{fontFamily: 'inherit'}}>
                        Welcome to Miele Coffee, where passion brews perfection in every cup. 
                        Renowned for our handcrafted espresso drinks made with natural, wholesome 
                        ingredients, we bring flavorful joy to the Dallas-Fort Worth Metroplex. 
                        From weddings to corporate events, bridal showers to birthdays, our mobile 
                        coffee service delivers exceptional taste and warm hospitality wherever we go.
                    </Typography>
                </Grid>
                <LandingPageQuoteOnPhoto/>
                {/* <Grid item xs={12} style={{maxHeight: '50vh', display: 'flex'}}>
                    <img className={classes.logoImage} src={stock2} style={{objectFit: 'cover'}} alt="landingImage2"/>
                </Grid> */}
                {/* <Grid item xs={12} style={{margin: '7vw'}}>
                    <Typography className={classes.LandingPageText}>
                    At Miele Coffee, serving goes beyond coffee; it's about crafting 
                        unforgettable moments. With a dedication to excellence and a commitment to 
                        delighting taste buds, we invite you to savor the art of our handcrafted brews 
                        and experience the essence of genuine care in every sip.
                    </Typography>
                </Grid> */}
                
            </Grid>
        </div>
    )
}

export default SmallLandingPage