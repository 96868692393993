import { Grid, Typography } from '@mui/material'
import React from 'react'

import useStyles from '../MobileStyles'
// import stock1 from '../../images/OfficialPhotos/OfficialPhoto4.png'
// import stock2 from '../../images/OfficialPhotos/OfficialPhoto8.png'

import stock1 from '../../images/OfficialPhotos/CoverPhoto1.png'
import stock2 from '../../images/OfficialPhotos/CoverPhoto2.png'

import KelseyAndMacaylaTogether1 from '../../images/OfficialPhotos/OfficialPhoto18.jpg'
import bg_photo from '../../images/OfficialPhotos/OfficialPhoto19.jpg'
import StarIcon from '@mui/icons-material/Star';
import LandingPageQuoteOnPhoto from '../../Components/LandingPageQuoteOnPhoto'

const MobileLandingPage = () => {
    const classes = useStyles();

    return (
        <div className={classes.MobileLandingPage}>
            <Grid container spacing={4}>
                <Grid item xs={12} className={classes.LandingHeaderText}>
                    {/** FIXME :: need to fix font */}
                    <Typography variant='h3' style={{fontFamily: 'inherit'}}>
                        Café De Miele
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
                    <Typography variant='h5' style={{alignItems: 'center', fontFamily: 'inherit', fontWeight: 500, paddingBottom: 40, textAlign: 'center', width: '100%', flex: 1}}>
                        DFW COFFEE CATERING
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{maxHeight: '50vh', display: 'flex'}}>
                    <img className={classes.logoImage} src={KelseyAndMacaylaTogether1} style={{objectFit: 'cover'}}/>
                </Grid>
                <Grid item xs={12} style={{margin: '7vw'}}>
                    
                    <Typography variant='h5' className={classes.LandingPageText} style={{alignItems: 'center', paddingBottom: 40, textAlign: 'center', width: '100%', flex: 1}}>
                        Welcome to Miele Coffee, where passion brews perfection in every cup. 
                        Renowned for our handcrafted espresso drinks made with natural, wholesome 
                        ingredients, we bring flavorful joy to the Dallas-Fort Worth Metroplex. 
                        From weddings to corporate events, bridal showers to birthdays, our mobile 
                        coffee service delivers exceptional taste and warm hospitality wherever we go.
                    </Typography>
                    
                </Grid>
                <Grid container>

                </Grid>
                <Grid container style={{marginTop: 50, width: '100vw', margin: 0, marginRight: 0, alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
                    <Grid xs={12} item style={{width: '100%'}}>
                    <div  style={{
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '50vh', // Full height of the viewport
                            // width: '100vw',
                            padding: 0,
                            margin: 0,
                            left: 32,
                            width: '100%',
                            marginRight: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                            backgroundImage:`url(${bg_photo})`}}>
                    {/* <img src={bg_photo} /> */}
                    <div style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background for the quote
                        padding: 4,
                        maxWidth: '70vw',
                        // borderRadius: theme.spacing(1),
                        textAlign: 'center',
                    }}>
                        {/* <h1 className={classes.quoteText}>

                        </h1> */}
                        <Typography style={{
                            color: 'white',
                            fontSize: '1rem',
                            // fontFamily: 'inherit',
                        }}>
                            “cafe de Miele was AMAZING!! They were so kind and made our guests experience
                            at our wedding so great!! They were friendly, welcoming, and very easy to work with. he loved it so much!!
                            They were easy to work with, prompt with responses and made our day that much more special!
                            Cannot recommend them enought!”
                        </Typography>

                        <div style={{color: 'white', margin: 20}}>
                            {[...Array(5)].map((_, index) => (
                            <StarIcon key={index} style={{fontSize: 30}}/>
                            ))}
                        </div>
                        <Typography className={classes.quoteText} style={{color: 'white'}}>
                            - 2024 Bride
                        </Typography>
                    </div>
                </div>
                    </Grid>
                </Grid>

            </Grid>
        </div>
    )
}

export default MobileLandingPage