import { Grid, List, ListItem, Typography } from '@material-ui/core'
import React from 'react'
import useStyles from '../pagesStyles';
// import coffeeCartFlowerPhoto from '../../Assets/OfficialPhotos/CoverPhoto2'
import coffeeCartFlowerPhoto from "../../images/OfficialPhotos/OfficialPhoto15.jpg"

const BookNowPanel = () => {
    const classes = useStyles();

    const [headerVariant, setHeaderVariant] = React.useState(40);
    const [bodyVariant, setBodyVariant] = React.useState(25)

    const fontStyle = 'inherit'

  return (
    <div className={classes.PricingPanel} style={{width: '100%', flexDirection: 'row', display: 'flex', alignItems: 'center', flex: 1, borderTop: '2px solid black', margin: 0, padding: 0}}>
        <Grid container style={{width: '100%',}}>
            <Grid item xs={7} style={{justifyContent: 'center', display: 'flex',}}>
                <div style={{height: '100%', paddingLeft: 30}}>
                    <div style={{height: '30%', justifyContent: 'flex-end', alignItems: 'center', flexDirection: 'column', display: 'flex'}}>
                        {/** Title **/}
                        <Typography style={{fontSize: headerVariant, fontFamily: fontStyle}}>
                            Book with Miele
                        </Typography>
                    </div>
                    <div style={{paddingTop: 30, alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                        {/** Description **/}
                        <Typography style={{fontSize: bodyVariant + 3, paddingLeft: 20, width: 400, textAlign: 'center', padding: 30}}>
                            We hope to bring the best coffe has to offer to your event!
                        </Typography>
                        <Typography style={{fontSize: bodyVariant + 3,  paddingLeft: 20}}>
                            Click "Get a Quote" to book now!
                        </Typography>

                    </div>    
                </div>
            </Grid>
            <Grid item xs={5} style={{justifyContent: 'center', display: 'flex',}}>
                <div>
                    {/** Image **/}
                    <img className={classes.Image} src={coffeeCartFlowerPhoto} alt="coffeeCartPhoto"/>
                </div>
            </Grid>
        
        </Grid>
    </div>
  )
}

export default BookNowPanel
