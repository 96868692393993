import { Grid, List, ListItem, Typography } from '@material-ui/core'
import React from 'react'
import useStyles from '../pagesStyles';
// import coffeeCartFlowerPhoto from '../../Assets/OfficialPhotos/CoverPhoto2'
import productPhoto from "../../images/OfficialPhotos/OfficialPhoto16.jpg"

const PopupCateringPanel = () => {
    const classes = useStyles();

    const [headerVariant, setHeaderVariant] = React.useState(40);
    const [bodyVariant, setBodyVariant] = React.useState(25)

    const fontStyle = 'inherit'

  return (
    <div className={classes.PricingPanel} style={{width: '100%', flexDirection: 'row', display: 'flex', alignItems: 'center', flex: 1}}>
        <Grid container style={{width: '100%',}}>

            <Grid item xs={5} style={{justifyContent: 'center', display: 'flex',}}>
                <div>
                    {/** Image **/}
                    <img className={classes.Image} src={productPhoto} alt="coffeeCartPhoto"/>
                </div>
            </Grid>
            <Grid item xs={7} style={{justifyContent: 'center', display: 'flex',}}>
                <div style={{height: '100%', paddingLeft: 30}}>
                    <div style={{height: '30%', justifyContent: 'flex-end', alignItems: 'center', flexDirection: 'column', display: 'flex'}}>
                        {/** Title **/}
                        <Typography style={{fontSize: headerVariant, fontFamily: fontStyle}}>
                            "Pop up" Catering
                        </Typography>
                    </div>
                    <div style={{paddingTop: 30}}>
                        {/** Description **/}
                        <Typography style={{fontSize: bodyVariant + 3, fontWeight: 'bold', fontStyle: 'italic', paddingLeft: 20}}>
                            Event guests pay for theeir own drinks. If the minimum is not met, host is responsible for the difference.
                        </Typography>
                        <List style={{fontSize: bodyVariant, paddingLeft: 20}}>
                        <ListItem >
                            {'\u2022'} Professional coffee equipment
                        </ListItem>
                        <ListItem >
                            {'\u2022'} 2 hour service time
                        </ListItem>
                        <ListItem >
                            {'\u2022'} Custom menu with three specialty latte flavors
                        </ListItem>
                        <ListItem >
                            {'\u2022'} Non dairy milk: oat milk included
                        </ListItem>
                        <ListItem >
                            {'\u2022'} Non coffee items: tea and hot chocolate
                        </ListItem>
                        <ListItem >
                            {'\u2022'} Decaf upon request
                        </ListItem>
                        </List>
                    </div>    
                </div>
            </Grid>
        </Grid>
    </div>
  )
}

export default PopupCateringPanel
