import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({ // the theme object is part of MUI
    MobileNavbar: {
        display: 'flex',
        backgroundColor: 'white',
        color: 'black', // #F9E4BC <-- cream color?
        borderBottom: '2px solid black',
    },
    Navbar: {
        display: 'flex',
        backgroundColor: 'white',
        color: 'black', // #F9E4BC <-- cream color?
        borderBottom: '2px solid black',
    },
    NavbarContainer: {
        width: '100%', 
    },
    logoContainer: {
        justifyContent:'left',
        display: 'flex',
        alignItems: 'left',
    },
    logoImage: {
        maxWidth: "100%",
        height: 'auto',
        cursor: 'pointer',
    },
    navbarSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100%', 
        width: '100%',
        flexDirection: 'row'
    },
    NavbarButtons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    TextButton: {
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    MenuContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    LandingHeaderText: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        textAlign: 'center',
        fontFamily: '"PP Hatton", sans-serif',
    },
    MobileLandingPage: {
        width: '100%',
        marginTop: 40,
        fontFamily: '"PP Hatton", sans-serif',
    },
    LandingPageText: {
        fontFamily: '"PP Hatton", sans-serif',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        textAlign: 'center',
        
    },
    MobilePricingPage: {
        width: '100%',
        marginTop: 40,
        // fontFamily: 'garamond'
        backgroundColor: '#efe9e4',
        color: '#5e5343',
        fontFamily: '"PP Hatton", sans-serif',

    },
    PageHeader: {
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'garamond'
    },
    MobileContactPage: {
        padding: 40
    },
    FormSubmitButtonContainer: {
        justifyContent: 'center',
        display: 'flex',   
        paddingTop: 40
    },

}));

export default useStyles;