import React from 'react'
import { Typography, Grid } from '@material-ui/core'

import useStyles from '../MobileStyles'
// import KelseyAndMacaylaPhoto from '../../images/KelseyAndMacaylaPhoto.png'
// import KelseyPhoto from '../../images/KelseyPhoto.png'
// import MacaylaPhoto from '../../images/MacaylaPhoto.jpeg'

import KelseyAndMacaylaPhoto from '../../images/OfficialPhotos/OfficialPhoto3.png'
import KelseyPhoto from '../../images/OfficialPhotos/OfficialPhoto6.png'
import MacaylaPhoto from '../../images/OfficialPhotos/OfficialPhoto2.png'

// import MacaylaPanel from '../AboutUsPage/MacaylaSmallPanel'
import mPhoto from '../../images/OfficialPhotos/OfficialPhoto17.jpg'
import coffeeClink from '../../images/OfficialPhotos/OfficialPhoto22.jpeg'

const MobileMissionPage = () => {
    const classes = useStyles();

    return (
        <div style={{justifyContent: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column', marginLeft: '7vw', marginRight: '7vw', marginTop: 40}}>
            <Grid container spacing={4} >
                <Grid item xs={12} className={classes.LandingHeaderText}>
                    <Typography variant='h4' style={{fontFamily: 'inherit'}}>
                        About Us
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{maxHeight: '50vh', display: 'flex'}}>
                    <img className={classes.logoImage} src={coffeeClink} style={{objectFit: 'cover'}} alt="Macayla and Kelsey"/>
                </Grid>
                <Grid item xs={12} style={{margin: '7vw'}}>
                    <Typography variant='h6' className={classes.LandingPageText} style={{}}>
                    Miele started as a long time dream of opening a coffee shop. Through wedding planning, 
                    the owner Macayla, fell in love with the event industry and thought of Miele. Miele was born 
                    with a passion for coffee and for lives special moments. Coffee brings people together, and Miele 
                    hopes to be a part of the community around us. 
                    </Typography>
                    <Typography variant='h6' className={classes.LandingPageText} style={{textAlign: 'center', paddingTop: 60}}>
                        Miele: noun. /MEE-el/honye <br/>
                        Éntre miele: to be all sweetness and light 
                    </Typography>

                </Grid>
                <Grid item xs={12} style={{maxHeight: '50vh', display: 'flex'}}>
                    <img className={classes.logoImage} src={mPhoto} style={{objectFit: 'cover'}} alt="Macayla"/>
                </Grid>
                <Grid item xs={12} style={{margin: '7vw'}}>
                    <Typography className={classes.LandingPageText}>
                    Macayla has loved coffee for as long as she can remember. Her passion grew in undergrad 
                    where she spent hours at coffee shops studying and spending time with friends. Macayla 
                    has always had the dream of opening up a coffee shop and is now making the dream a reality with Miele!
                    </Typography>
                </Grid>

            </Grid>
        </div>
    )
}

export default MobileMissionPage