import React from 'react'
import mPhoto from '../../images/OfficialPhotos/OfficialPhoto17.jpg'
import useStyles from '../pagesStyles'
import { Grid, Typography } from '@material-ui/core'

const MacaylaPanel = () => {
  const classes = useStyles()
  const [headerVariant, setHeaderVariant] = React.useState(40);
  const [bodyVariant, setBodyVariant] = React.useState(25)

  const fontStyle = 'inherit'

return (
  <div className={classes.PricingPanel} style={{width: '100%', flexDirection: 'row', display: 'flex', alignItems: 'center', flex: 1, paddingTop: 60}}>
      <Grid container style={{width: '100%',}}>
          <Grid item xs={5} style={{justifyContent: 'center', display: 'flex',}}>
              <div>
                  {/** Image **/}
                  <img className={classes.Image} src={mPhoto} alt="MacaylaPhoto"/>
              </div>
          </Grid>
          <Grid item xs={7} style={{justifyContent: 'center', display: 'flex',}}>
              <div style={{height: '100%', paddingLeft: 30}}>
                  <div style={{height: '30%', justifyContent: 'flex-end', alignItems: 'center', flexDirection: 'column', display: 'flex'}}>
                      {/** Title **/}
                      <Typography variant='h3' style={{fontFamily: 'inherit', textAlign: 'center', paddingTop: 60}}>
                          Miele: noun. /MEE-el/honye <br/>
                      </Typography>
                  </div>
                  <div style={{paddingTop: 30}}>
                      {/** Description **/}
                      <Typography style={{fontSize: bodyVariant + 3, fontWeight: 'bold', fontStyle: 'italic', paddingLeft: 20}}>
                      Éntre miele: to be all sweetness and light 
                      </Typography>
                      <Typography style={{fontSize: bodyVariant + 3,  paddingLeft: 20, paddingTop: 40}}>
                        Macayla has loved coffee for as long as she can remember. Her passion grew in undergrad where she spent hours at coffee shops studying and spending time with friends. Macayla has always had the dream of opening up a coffee shop and is now making the dream a reality with Miele!

                        </Typography>
                  </div>    
              </div>
          </Grid>

      
      </Grid>
  </div>
)
}

export default MacaylaPanel
