import { makeStyles } from '@material-ui/core/styles'
import backgroundImage from '../images/OfficialPhotos/OfficialPhoto19.jpg';

// hook called useStyles
const useStyles = makeStyles((theme) => ({ // the theme object is part of MUI
    footer: {
        // background: 'white',
        backgroundColor: "#efe9e4",
        color: 'black',
        padding: '50px',
        paddingTop: 50,
        fontFamily: 'garamond'
    },
    footerText: {
        color: 'inherit',
        fontFamily: 'inherit',
    },
    Navbar: {
        display: 'flex',
        backgroundColor: 'white',
        color: 'black', // #F9E4BC <-- cream color?
        borderBottom: '2px solid black',
    },
    NavbarContainer: {
        width: '100%',
    },
    logoContainer: {
        justifyContent:'left',
        display: 'flex',
        alignItems: 'left',
    },
    logoImage: {
        maxWidth: "100%",
        height: 'auto',
        cursor: 'pointer',
    },
    navbarSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100%', 
        width: '100%'
    },
    NavbarButtons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    TextButton: {
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    accordion: {
        backgroundColor: 'inherit',
        boxShadow: 'none', // Remove box shadow
        border: 'none',    // Remove border
        '&:before': {
          display: 'none',  // Hide the accordion summary border
        },
        fontFamily: '"PP Hatton", "Garbata", sans-serif'
      },
      accordionSummary: {
        '& .MuiAccordionSummary-expandIcon': {
          display: 'none',  // Hide the expand icon
        },
      },
    accordianHeaders: {
        fontFamily: 'garamond',
        color: 'inherit'
    },
    boldHeader: {
        fontWeight: 'bold',
        textShadow: '1px 0px 0px rgba(0, 0, 0, 1)'
    },
    backgroundContainer: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh', // Full height of the viewport
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      },
      quoteContainer: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background for the quote
        padding: theme.spacing(4),
        maxWidth: '70vw',
        borderRadius: theme.spacing(1),
        textAlign: 'center',
      },
      quoteText: {
        color: 'white',
        fontSize: '2rem',
        fontFamily: '"PP Hatton", "Garbata", sans-serif', // Using your custom fonts
        fontWeight: 500, // Use the bold weight
      },
    

}));

export default useStyles;