import { Typography, Grid } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import VerticalAccordion from '../Components/VerticalAccordian';
import useStyles from './pagesStyles';

// import stock1 from '../images/OfficialPhotos/OfficialPhoto4.png'
import stock1 from '../images/OfficialPhotos/OfficialPhoto11.png'
import ContractCateringPanel from './PricingPage/ContractCateringPanel';
import PopupCateringPanel from './PricingPage/PopupCateringPanel';
import AddOnsPanel from './PricingPage/AddOnsPanel';
import BookNowPanel from './PricingPage/BookNowPanel';
import MobilePricingPage from '../Mobile/MobilePages/MobilePricingPage';

const PricingPage = () => {
    const bodyVariant='h6'
    const classes = useStyles();

    const [isTooSmall, setIsTooSmall] = useState(window.innerWidth < 1100);
    useEffect(() => {
        const handleResize = () => {
            console.log('window.innerWidth <= 1100: ', window.innerWidth <= 1100)
            setIsTooSmall(window.innerWidth <= 1100);
        };

        handleResize()

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (isTooSmall) { return ( <MobilePricingPage/> ) }

    // FIXME NEED TO FIX FOR SMALL PAGES
    return (
        <div className={classes.PricingPage}> 
            {/* <Grid container spacing={4} >
                <Grid item xs={6} lg={7} className={classes.TextBody}>
                    <VerticalAccordion/>
                </Grid>
                <Grid item xs={6} lg={5}>
                    <img className={classes.Image} src={stock1} alt="Coffee"/>
                </Grid> 
            </Grid>  */}
            <ContractCateringPanel/>
            <PopupCateringPanel/>
            <AddOnsPanel/>
            <BookNowPanel/>
        </div>
    )
}

export default PricingPage