import React, { useState, useEffect} from 'react'
import Navbar from '../Components/Navbar';
import { Grid, Typography } from '@material-ui/core';
import LandingPageQuoteOnPhoto from '../Components/LandingPageQuoteOnPhoto';

import useStyles from './pagesStyles';

// import stock1 from '../images/OfficialPhotos/OfficialPhoto4.png'
// import stock2 from '../images/OfficialPhotos/OfficialPhoto8.png'
import stock1 from '../images/OfficialPhotos/CoverPhoto1.png'
import stock2 from '../images/OfficialPhotos/CoverPhoto2.png'

import KelseyAndMacaylaTogether1 from '../images/OfficialPhotos/OfficialPhoto18.jpg'
import QuoteCarousel from '../Components/QuoteCarousel';
import SmallLandingPage from './SmallWindowPages/SmallLandingPage';

const quotes = ["SOME FAKE QUOTE", "SOME OTHER FAKE QUOTE"];

const LandingPage = () => {
    const classes = useStyles();
    const [isTooSmall, setIsTooSmall] = useState(window.innerWidth < 1100);
    useEffect(() => {
        const handleResize = () => {
            console.log('window.innerWidth <= 1100: ', window.innerWidth <= 1100)
            setIsTooSmall(window.innerWidth <= 1100);
        };

        handleResize()

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (isTooSmall) { return ( <SmallLandingPage stock1={stock1} stock2={stock2}/> ) }
    return (
        <div className={classes.LandingPage}>
            <Grid container className={classes.ImageAndTextContainer} style={{marginTop: 50}}>
                <Grid item xs={8} md={6} className={classes.ImageContainer}>
                    <img className={classes.Image} src={KelseyAndMacaylaTogether1} alt="KelseyAndMacaylaTogether1"/>
                </Grid>
                <Grid item xs={4} md={6} className={classes.TextBody}>
                    <div>
                    <Typography variant='h1' style={{fontFamily: 'inherit', fontWeight: 500, paddingBottom: 40, textAlign: 'left', width: '100%', flex: 1}}>
                        Café De Miele
                    </Typography>
                    <Typography variant='h4' style={{fontFamily: 'inherit', fontWeight: 500, paddingBottom: 40, textAlign: 'left', width: '100%', flex: 1}}>
                        DFW COFFEE CATERING
                    </Typography>
                    <Typography variant='h5' style={{fontFamily: "inherit", textAlign: 'left', width: '100%'}}>
                        Welcome to Miele Coffee, where passion brews perfection in every cup. 
                        Renowned for our handcrafted espresso drinks made with natural, wholesome 
                        ingredients, we bring flavorful joy to the Dallas-Fort Worth Metroplex. 
                        From weddings to corporate events, bridal showers to birthdays, our mobile 
                        coffee service delivers exceptional taste and warm hospitality wherever we go.
                    </Typography>
                    </div>
                    
                </Grid>
            </Grid>

            <Grid container className={classes.ImageAndTextContainer} style={{marginTop: 50}}>
                <LandingPageQuoteOnPhoto/>
            </Grid>

            {/* <Grid container className={classes.ImageAndTextContainer} style={{marginTop: 50, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
            <Grid container style={{marginTop: 50, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <QuoteCarousel quotes={quotes}/>
            </Grid> */}
        </div>
    )
}

export default LandingPage