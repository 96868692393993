import { Grid, List, ListItem, Typography } from '@material-ui/core'
import React from 'react'
import useStyles from '../pagesStyles';
// import coffeeCartFlowerPhoto from '../../Assets/OfficialPhotos/CoverPhoto2'
import coffeeCartFlowerPhoto from "../../images/OfficialPhotos/OfficialPhoto21.jpeg"

const AddOnsPanel = () => {
    const classes = useStyles();

    const [headerVariant, setHeaderVariant] = React.useState(40);
    const [bodyVariant, setBodyVariant] = React.useState(25)

    const fontStyle = 'inherit'

  return (
    <div className={classes.PricingPanel} style={{width: '100%', flexDirection: 'row', display: 'flex', alignItems: 'center', flex: 1}}>
        <Grid container style={{width: '100%',}}>
            <Grid item xs={7} style={{justifyContent: 'center', display: 'flex',}}>
                <div style={{height: '100%', paddingLeft: 30}}>
                    <div style={{height: '30%', justifyContent: 'flex-end', alignItems: 'center', flexDirection: 'column', display: 'flex'}}>
                        {/** Title **/}
                        <Typography style={{fontSize: headerVariant, fontFamily: fontStyle}}>
                            Add Ons
                        </Typography>
                    </div>
                    <div style={{paddingTop: 30}}>
                        {/** Description **/}
                        <Typography style={{fontSize: bodyVariant + 3, fontWeight: 'bold', fontStyle: 'italic', paddingLeft: 20}}>
                            Add ons can be added to either package!
                        </Typography>
                        <List style={{fontSize: bodyVariant, paddingLeft: 20}}>
                        <ListItem >
                            {'\u2022'} Additional service Time
                        </ListItem>
                        <ListItem >
                            {'\u2022'} Custom branding on cups
                        </ListItem>
                        <ListItem >
                            {'\u2022'} Additional latte flavors
                        </ListItem>
                        <ListItem >
                            {'\u2022'} Matcha and Chai
                        </ListItem>
                        </List>
                    </div>    
                </div>
            </Grid>
            <Grid item xs={5} style={{justifyContent: 'center', display: 'flex',}}>
                <div>
                    {/** Image **/}
                    <img className={classes.Image} src={coffeeCartFlowerPhoto} alt="coffeeCartPhoto"/>
                </div>
            </Grid>
        
        </Grid>
    </div>
  )
}

export default AddOnsPanel
