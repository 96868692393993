import React from 'react'
import { Typography, Grid } from '@material-ui/core'

import useStyles from './smallPagesStyles'
import coffeeClink from '../../images/OfficialPhotos/OfficialPhoto22.jpeg'
import mPhoto from '../../images/OfficialPhotos/OfficialPhoto17.jpg'

const SmallMissionPage = (props) => {
    const classes = useStyles();
    const KelseyAndMacaylaPhoto = props.KelseyAndMacaylaPhoto
    const MacaylaPhoto = props.MacaylaPhoto
    const KelseyPhoto = props.KelseyPhoto
    const [bodyVariant, setBodyVariant] = React.useState(25)

    return (
        <div style={{justifyContent: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column'}} className={classes.LandingPage}>
        <Grid container spacing={4} style={{maxWidth: '100%'}}>
          <Grid item xs={12} >
            <Typography variant='h1' style={{fontFamily: 'inherit', textAlign: 'center', paddingTop: 40}}>
              About Us
            </Typography>
            <img src={coffeeClink} alt='coffeeClink' style={{ flex: 1, maxWidth: '40%'}}/>
            <div style={{width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: 60}}>
              <Typography variant='h4' style={{textAlign: 'center', width: '70%', fontFamily: 'Garbata'}}>
                Miele started as a long time dream of opening a coffee shop. Through wedding planning, 
                the owner Macayla, fell in love with the event industry and thought of Miele. Miele was born 
                with a passion for coffee and for lives special moments. Coffee brings people together, and Miele 
                hopes to be a part of the community around us. 
              </Typography>
            </div> 
          </Grid>
        </Grid>

        <Grid container>
            <Grid item xs={12}>
                <img className={classes.logoImage} src={mPhoto} alt="MacaylaPhoto"/>
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h4' style={{fontFamily: 'inherit', textAlign: 'center', paddingTop: 60}}>
                    Miele: noun. /MEE-el/honye <br/>
                </Typography>
                <Typography style={{fontSize: bodyVariant + 3, fontWeight: 'bold', fontStyle: 'italic', paddingLeft: 20}}>
                      Éntre miele: to be all sweetness and light 
                </Typography>
                <Typography style={{fontSize: bodyVariant + 3,  paddingLeft: 20, paddingTop: 40}}>
                    Macayla has loved coffee for as long as she can remember. Her passion grew in undergrad where she spent hours at coffee shops studying and spending time with friends. Macayla has always had the dream of opening up a coffee shop and is now making the dream a reality with Miele!
                </Typography>
            </Grid>

        </Grid>

        {/* <MacaylaPanel/> */}
      </div>
    )
}

export default SmallMissionPage