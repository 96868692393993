import React from 'react'
import useStyles from './componentStyles'
import StarIcon from '@mui/icons-material/Star';

import bg_photo from '../images/OfficialPhotos/OfficialPhoto19.jpg'
import { Typography } from '@material-ui/core'

const LandingPageQuoteOnPhoto = () => {
    const classes = useStyles()
  return (
    <div className={classes.backgroundContainer} style={{backgroundImage:`url(${bg_photo})`}}>
        {/* <img src={bg_photo} /> */}
        <div className={classes.quoteContainer}>
            {/* <h1 className={classes.quoteText}>

            </h1> */}
            <Typography className={classes.quoteText}>
                “cafe de Miele was AMAZING!! They were so kind and made our guests experience
                at our wedding so great!! They were friendly, welcoming, and very easy to work with. he loved it so much!!
                They were easy to work with, prompt with responses and made our day that much more special!
                Cannot recommend them enought!”
            </Typography>

            <div style={{color: 'white', margin: 20}}>
                {[...Array(5)].map((_, index) => (
                <StarIcon key={index} style={{fontSize: 50}}/>
                ))}
            </div>
            <Typography className={classes.quoteText}>
                - 2024 Bride
            </Typography>
        </div>
    </div>
  )
}

export default LandingPageQuoteOnPhoto
